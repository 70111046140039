import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

class About extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>About Justin Kwan Lee</title>
          {/* <meta name="description" content={post.description} /> */}
        </Helmet>
        <div id="main">
          <section id="one">
            <h1>About Me</h1>

            <p>
              Justin is a graduate of McMaster University who specializes in
              technology and management. His experience spans over 10 years and
              he had worked for several industries including:
            </p>
            <ul>
              <li>Healthcare</li>
              <li>Insurance</li>
              <li>Telecommunications</li>
              <li>Travel</li>
              <li>Security / Assistance / Duty of Care</li>
            </ul>

            <p>He has worked with several organizations including:</p>
            <ul>
              <li>
                <a
                  href="https://www.slalombuild.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Slalom Build
                </a>
              </li>
              <li>
                <a
                  href="https://www.ingleinternational.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MSH Ingle International
                </a>
              </li>
              <li>
                <a
                  href="https://www.novushealth.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Novus Health
                </a>
              </li>
              <li>
                <a
                  title="SITA"
                  href="http://www.sita.aero"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SITA (Société Internationale de Télécommunications
                  Aéronautiques)
                </a>
              </li>
              <li>
                <a
                  title="Catholic Children's Aid Society of Hamilton"
                  href="http://www.hamiltonccas.on.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Catholic Children’s Aid Society
                </a>
              </li>
            </ul>

            <p>
              You can check out my{' '}
              <a
                href="https://www.linkedin.com/in/justinkwanlee/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>{' '}
              for more information.
            </p>
          </section>
        </div>
      </Layout>
    );
  }
}

export default About;
